<template>
  <div class="page">
    <van-nav-bar
      fixed
      title="设备文档"
      left-arrow
      @click-left="navBack"
    ></van-nav-bar>
    <div class="search">
      <div ref="filterBoxWrapper" class="filter-box-wrapper">
        <div ref="filterBox" class="filter-box">
          <span
            v-for="(item, index) in filterList"
            ref="filterItems"
            :key="index"
            class="filter-item"
            :class="[item.type, { active: item.isActive || item.text }]"
            @click="selectCon(item.popupKey)"
            >{{ item.text || item.name }}</span
          >
        </div>
        <div class="search-condition">
          <div class="alarm-total">共{{ total }}条结果</div>
        </div>
      </div>
    </div>
    <van-pull-refresh
      ref="vanList"
      v-model="refreshing"
      class="data-list"
      @refresh="pullGetAgain"
    >
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="finishedText || '没有更多了'"
        error-text="加载失败，请稍后再试！"
        @load="onLoad"
      >
        <div
          v-for="item in list"
          :key="item.id"
          class="data-item"
          @click="gotoDetail(item)"
        >
          <div class="data-container">
            <div class="data-icon">
              <img :src="require(`@/assets/images/equipment/default.png`)" />
            </div>
            <div class="data-title">
              {{ item.fileName }}
            </div>
            <div class="data-status">
              <van-icon
                v-if="item.fileDTO"
                :name="'fujian'"
                class-prefix="iconfont"
                :class="`iconfont-fujian`"
                color="#AEB3C0"
                size="16"
              />
              <van-icon
                v-if="item.fileDesc"
                :name="'jianjie1'"
                class-prefix="iconfont"
                :class="`iconfont-jianjie1`"
                color="#AEB3C0"
                size="16"
              />
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>

    <!-- 查询条件弹窗   -->
    <template v-for="(item, idx) in popupVisibleObj">
      <SelectPopupGrade
        :key="idx"
        v-model="item.value"
        :immediately="false"
        list-key="value"
        :title="item.title"
        :list.sync="item.list"
        :visible="item.visiable"
        @change="v => popupGradeChange(v, idx)"
        @close="item.visiable = false"
      />
    </template>
  </div>
</template>

<script>
import { getEquipmentDoc, getBasicParams } from "./api";
import { mapState } from "vuex";

export default {
  name: "EquipmentDoc",
  data() {
    return {
      filterList: [
        {
          name: "文档类型",
          type: "select",
          text: "",
          popupKey: 0,
          isActive: false
        }
      ],
      popupVisibleObj: [
        {
          visiable: false,
          title: "文档类型",
          value: "",
          list: []
        }
      ],
      list: [],
      query: {
        page: 1,
        size: 10,
        type: ""
      },
      total: 0,
      refreshing: false,
      loading: false,
      finishedText: "",
      finished: false
    };
  },
  computed: {
    statusIconSwitch() {
      return status => {
        const obj = {
          default: "default",
          word: "word",
          pdf: "pdf",
          exl: "exl",
          jpgpng: "jpg-png",
          mp4: "mp4"
        };
        return obj[status];
      };
    }
  },
  created() {
    this.$nextTick(() => {
      this.getBasicParams("documentType");
    });
  },
  methods: {
    getList() {
      getEquipmentDoc({
        equipmentRecordId: this.$route.params.id,
        ...this.query
      }).then(res => {
        this.loading = false;
        if (res.list && res.list.length) {
          this.total = res.total;
          this.list = this.list.concat(res.list);
          this.query.page++;
        } else {
          if (!this.list.length) {
            this.finishedText = "没有更多了";
          } else {
            this.finishedText = "";
          }
          this.finished = true;
        }
      });
    },
    //跳转详情页面
    gotoDetail(item) {
      this.$router.push({ name: "equipmentDocDetail", params: { doc: item } });
    },
    async getBasicParams(code) {
      await getBasicParams(code).then(res => {
        switch (code) {
          case "documentType":
            this.popupVisibleObj[0].list = res.unDelete;
            break;
        }
      });
    },
    onSearch() {
      this.finished = false;
      this.loading = true;
      this.query.page = 1;
      this.list = [];
      this.total = 0;
      this.getList();
    },
    popupGradeChange(v, idx) {
      const vObj = v.length > 0 ? v[0] : {};
      this.filterList[idx].text = vObj.label || "";
      this.popupVisibleObj[idx].value = vObj.value || "";
      if (vObj.value) {
        if (idx === 0) {
          this.query.type = vObj.value || "";
        }
      }
      this.onSearch();
    },
    //点击保养类型与保养状态
    async selectCon(popupKey) {
      this.popupVisibleObj[popupKey].visiable = true;
    },
    //下拉刷新
    pullGetAgain() {
      // 清空列表数据
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      // 将 page 重新设置为 1
      this.query.page = 1;
      this.onLoad();
    },
    onLoad() {
      if (this.refreshing) {
        this.list = [];
        this.refreshing = false;
      }
      this.getList();
    },
    scrollTo() {
      this.scrollHeight = this.$refs.vanList.$el.scrollTop;
    },
    navBack() {
      this.$router.back();
    }
  }
};
</script>

<style lang="scss" scoped>
.page {
  .search {
    position: relative;
    margin-top: 58px;

    .filter-box-wrapper {
      width: 100%;
    }

    .filter-box {
      position: relative;
      height: 50px;
      font-size: 14px;
      border-bottom: 2px solid #eceef2;
      overflow-x: scroll;
      white-space: nowrap;

      &::-webkit-scrollbar {
        display: none;
      }

      .filter-item {
        display: inline-block;
        position: absolute;
        top: 18px;
        left: 0;
        color: #3b4664;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        box-sizing: border-box;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:first-child {
          margin-left: 16px;
        }

        &.select {
          position: relative;
          padding-right: 18px;

          &::after {
            position: absolute;
            content: "";
            right: 6px;
            top: 5.5px;
            border: 4px solid transparent;
            border-top-color: #aeb3c0;
          }

          &.active {
            &::after {
              border-top-color: $--color-primary;
            }
          }
        }

        &.active {
          color: $--color-primary;
        }
      }
    }

    .search-condition {
      display: flex;
      justify-content: center;

      .loading {
        display: inline-block;
      }

      .van-loading__spinner {
        width: 4vw;
        height: 4vw;
      }

      .alarm-total {
        color: #8c8f97;
        text-align: center;
        font-size: 11.15px;
        padding: 11px 0 0;
        height: 17.55px;
        line-height: 17.55px;
        font-weight: 400;
      }

      .condition-switch {
        color: $--color-icon;

        i {
          font-size: 5vw;
        }
      }
    }
  }

  .data-list {
    .data-item {
      font-size: 14px;
      font-weight: 550;
      color: #2e2e4d;
      padding: 16px 16px 16px 0;
      margin-left: 16px;
      border-bottom: 1px solid #eceef2;

      .data-container {
        overflow: hidden;

        > div {
          float: left;
          vertical-align: middle;

          &:first-child {
            margin-right: 4px;
          }
        }

        .data-title {
          width: 250px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .data-status {
          .iconfont {
            margin-left: 20px;
          }
        }
      }
    }
  }
}
</style>
